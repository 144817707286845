<template>
  <div>
    <div class="fv">
      <video ref="player" autoplay muted playsinline loop>
        <source src="/video/firstview.mp4" />
        このブラウザではサポートされていません。
      </video>
    </div>

    <div class="fv-message">
      <h1>社会から必要不可欠とされる<br>企業を目指して</h1>
      <img src="@/assets/fv-img.webp" alt="契約数30,000超、お客様満足度 業界最高水準、販売実績3年連続 日本1位" class="fv-img pc-tablet-only">
      <img src="@/assets/fv-img_sp.webp" alt="契約数30,000超、お客様満足度 業界最高水準、販売実績3年連続 日本1位" class="fv-img sp-only">
      <h2>株式会社 日本通信基地局</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstView"
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@400;500;600;700;900&display=swap');

  .fv {
    position: relative;
    height: 100svh;
    overflow: hidden;
    background-color: var(--sub1);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 100svw;
      height: auto;
      aspect-ratio: 16 / 9;
    }
    @media screen and (max-width: 767px) {
      height: 60svh;
    }
    video {
      position: absolute;
      top: calc(50% + 70px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      @media screen and (min-width: 1101px) and (max-width: 1400px) {
        top: calc(50% + 50px);
        width: 125%;
      }
      @media screen and (max-width: 767px) {
        width: 150%;
        top: 70px;
        transform: translate(-50%, 0);
      }
      @media screen and (max-width: 320px) {
        width: 120%;
      }
    }
  }
  .fv-message {
    position: absolute;
    top: 65%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 2;
    display: flex;
    justify-content: start;
    align-content: center;
    flex-wrap: wrap;
    width: 680px;
    height: 45%;
    padding: 0 5%;
    background-color: var(--cover);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 40%;
      height: auto;
      top: 30%;
      padding: 20px 2%;
    }
    @media screen and (max-width: 767px) {
      position: static;
      transform: translate(0, 0);
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      height: 40svh;
      width: 100%;
      padding: 0;
    }
    h1 {
      width: 100%;
      font-family: "Zen Old Mincho", serif;
      font-weight: 900;
      font-size: 32px;
      line-height: 2.5rem;
      letter-spacing: 0.15em;
      text-align: justify;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 20px;
        line-height: 2rem;
        letter-spacing: 0;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 767px) {
        font-size: 25px;
        line-height: 2rem;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 320px) {
        font-size: 20px;
      }
    }
    .fv-img {
      width: 100%;
      @media screen and (max-width: 767px) {
        width: 95%;
      }
    }
    h2 {
      font-family: "Zen Old Mincho", serif;
      font-weight: 900;
      margin-top: 20px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 18px;
        letter-spacing: 0;
        margin-top: 10px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 20px;
        text-align: center;
        margin-top: 10px;
      }
      @media screen and (max-width: 320px) {
        font-size: 18px;
      }
    }
  }
</style>