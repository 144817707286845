<template>
  <section>
    <div class="wrap">
        <div class="title">
          <h2>Member</h2>
          <h3>メンバー</h3>
        </div>
    </div>
    <!-- Splide document：https://ja.splidejs.com/documents/ -->
    <InfiniteSlider class="slider" :images="images" :sp_left_images="sp_left_images" :sp_right_images="sp_right_images" />
  </section>
</template>

<script>
import InfiniteSlider from '../Ui/InfiniteSlider.vue';

export default {
  name: "MemberVue",
  components: {
    InfiniteSlider,
  },
  data() {
    return {
      images: [ // PC表示用
        { id: 1, imgpath: "member_001.webp", name: "member_001" },
        { id: 2, imgpath: "member_002.webp", name: "member_002" },
        { id: 3, imgpath: "member_003.webp", name: "member_003" },
        { id: 4, imgpath: "member_004.webp", name: "member_004" },
        { id: 5, imgpath: "member_005.webp", name: "member_005" },
        { id: 6, imgpath: "member_006.webp", name: "member_006" },
        { id: 7, imgpath: "member_007.webp", name: "member_007" },
        { id: 8, imgpath: "member_008.webp", name: "member_008" },
        { id: 9, imgpath: "member_009.webp", name: "member_009" },
        { id: 10, imgpath: "member_010.webp", name: "member_010" },
        { id: 11, imgpath: "member_001.webp", name: "member_011" },
        { id: 12, imgpath: "member_002.webp", name: "member_012" },
        { id: 13, imgpath: "member_003.webp", name: "member_013" },
        { id: 14, imgpath: "member_004.webp", name: "member_014" },
        { id: 15, imgpath: "member_005.webp", name: "member_015" },
        { id: 16, imgpath: "member_006.webp", name: "member_016" },
        { id: 17, imgpath: "member_007.webp", name: "member_017" },
        { id: 18, imgpath: "member_008.webp", name: "member_018" },
        { id: 19, imgpath: "member_009.webp", name: "member_019" },
        { id: 20, imgpath: "member_010.webp", name: "member_020" },
      ],
      sp_right_images: [ // SP上側表示用
        { id: 1, imgpath: "member_001.webp", name: "member_001" },
        { id: 2, imgpath: "member_002.webp", name: "member_002" },
        { id: 3, imgpath: "member_003.webp", name: "member_003" },
        { id: 4, imgpath: "member_004.webp", name: "member_004" },
        { id: 5, imgpath: "member_005.webp", name: "member_005" },
        { id: 10, imgpath: "member_010.webp", name: "member_010" },
        { id: 11, imgpath: "member_001.webp", name: "member_011" },
        { id: 12, imgpath: "member_002.webp", name: "member_012" },
        { id: 13, imgpath: "member_003.webp", name: "member_013" },
        { id: 14, imgpath: "member_004.webp", name: "member_014" },
        { id: 15, imgpath: "member_005.webp", name: "member_015" },
      ],
      sp_left_images: [  // SP下側表示用
        { id: 6, imgpath: "member_006.webp", name: "member_006" },
        { id: 7, imgpath: "member_007.webp", name: "member_007" },
        { id: 8, imgpath: "member_008.webp", name: "member_008" },
        { id: 9, imgpath: "member_009.webp", name: "member_009" },
        { id: 10, imgpath: "member_010.webp", name: "member_010" },
        { id: 16, imgpath: "member_006.webp", name: "member_016" },
        { id: 17, imgpath: "member_007.webp", name: "member_017" },
        { id: 18, imgpath: "member_008.webp", name: "member_018" },
        { id: 19, imgpath: "member_009.webp", name: "member_019" },
        { id: 20, imgpath: "member_010.webp", name: "member_020" },
      ],
    }
  },
}
</script>

<style lang="scss">
section {
    background-color: var(--sub3);
    padding: 75px 0 0 0;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      padding: 40px 0 0 0;
    }
    @media screen and (max-width: 767px) {
      background-color: var(--white);
      padding: 60px 0;
    }
  .title {
    width: 35%;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    h2 {
      font-size: 70px;
      font-weight: 600;
      line-height: 70px;
      text-align: start;
      color: var(--sub1);
      margin-bottom: 10px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 55px;
        line-height: 55px;
      }
      @media screen and (max-width: 767px) {
        font-size: 37px;
        line-height: 37px;
        text-align: center;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: var(--sub1);
      @media screen and (max-width: 767px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
</style>