<template>
  <section id="policy">
    <div class="wrap">
      <div class="policy">
        <div class="title">
          <h2>Policy</h2>
          <h3>経営理念・行動基準</h3>
        </div>
        <div class="content">
          <div class="policy-content">
            <h4 class="policy-title">社会から必要不可欠とされる企業であり続けること</h4>
            <p class="policy-description">
              顧客、取引先様、従業員、地域社会にとって、当社が必要不可欠、無くてはならない企業を目指しております。そのためには、展開している事業では業界No.1の実績、No.1の品質を追求し達成するべきだと考えております。No.1の実績だからこそ、社会に貢献できる範囲が広がり、No.1の品質だからこそ、弊社独自の価値を社会に提供できるためです。今後も業界No.1にこだわりをもち、企業成長を続けてまいりたいと思います。
            </p>
          </div>
          <div class="policy-content" style="margin-bottom: 0;">
            <h4 class="policy-title">私たちは以下の人材となるべく<br />日々お仕事に向き合っています</h4>
            <p class="policy-description">
              ①顧客の成功が自分の成功だと捉え、価値を提供できる人
              <br>②規律を重んじ、信頼される人
              <br>③常識を疑い、新たな価値を創造できる人
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="side-cover">
      <!-- 白背景 -->
    </div>
  </section>
</template>

<script>
export default {
  name: "PolicyVue",
}
</script>

<style lang="scss" scoped>
  section {
    position: relative;
    height: calc(240px + 380px);
    padding: 120px 0;
    background-color: var(--sub3);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: calc(160px + 600px);
    }
    @media screen and (max-width: 767px) {
      padding: 50px 0;
      height: auto;
      background-color: var(--white);
    }
    .wrap {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 120px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
      .policy {
        display: flex;
        flex-wrap: wrap;
        height: 380px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 1050px;
        }
        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
        }
        .title {
          width: 35%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          h2 {
            font-size: 70px;
            font-weight: 600;
            line-height: 70px;
            text-align: start;
            color: var(--sub1);
            margin-bottom: 10px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 55px;
              line-height: 55px;
            }
            @media screen and (max-width: 767px) {
              font-size: 37px;
              line-height: 37px;
              text-align: center;
            }
          }
          h3 {
            font-size: 18px;
            font-weight: 500;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }
        }
        .content {
          width: 65%;
          height: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
          }
          .policy-content {
            padding-left: 20px;
            border-left: 20px solid var(--sub1);
            margin-bottom: 40px;
            @media screen and (max-width: 767px) {
              padding-top: 5px;
              padding-bottom: 5px;
            }
            .policy-title {
              font-size: 23px;
              font-weight: 500;
              line-height: 30px;
              color: var(--sub1);
              margin-bottom: 10px;
              @media screen and (max-width: 767px) {
                font-size: 21px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
    .side-cover {
      position: absolute;
      top: 0;
      right: 0;
      width: 1600px;
      height: calc(240px + 380px);
      padding: 120px;
      z-index: 1;
      background-color: var(--white);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 0;
        height: calc(160px + 600px);
      }
      @media screen and (max-width: 767px) {
        display: none;
      } 
    }
  }
</style>
