<template>
  <div class="home">
    <FirstView />
    <AboutUs />
    <Member />
    <Service />
    <Voice />
    <Achievements />
    <Policy />
    <OfficeInformation />
    <Contact />
  </div>
</template>

<script>
import FirstView from '@/components/Home/FirstView.vue';
import AboutUs from '@/components/Home/AboutUs.vue';
import Member from '@/components/Home/Member.vue';
import Service from '@/components/Home/Service.vue';
import Voice from '@/components/Home/Voice.vue';
import Achievements from '@/components/Home/Achievements.vue';
import Policy from '@/components/Home/Policy.vue';
import OfficeInformation from '@/components/Home/OfficeInformation.vue';
import Contact from '@/components/Home/Contact.vue';

export default {
  name: 'HomeView',
  components: {
    FirstView,
    AboutUs,
    Member,
    Service,
    Voice,
    Achievements,
    Policy,
    OfficeInformation,
    Contact,
  },
}
</script>
