<template>
  <section>
    <div class="wrap">
      <div id="info" class="office-information">
        <div class="title">
          <h2>Company<br />Information</h2>
          <h3>会社情報</h3>
        </div>
        <div class="content">
          <div class="info-list">
            <div class="info-row">
              <h4 class="info-title">会社名</h4>
              <p class="info-description">株式会社日本通信基地局</p>
            </div>
            <div class="info-row">
              <h4 class="info-title">住所</h4>
              <p class="info-description">〒104-0061<br>東京都中央区銀座1-12-4<br class="sp-only tablet-only">N&E BLD.7階</p>
            </div>
            <div class="info-row">
              <h4 class="info-title">電話番号</h4>
              <p class="info-description">
                <a href="tel:05017827335" class="phone-number">050-1782-7335</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-cover">
      <!-- 白背景 -->
    </div>
  </section>
</template>

<script>
export default {
  name: "OfficeInformationVue",
}
</script>

<style lang="scss" scoped>
  section {
    position: relative;
    height: calc(240px + 240px + 200px);
    padding: 120px 0;
    background-color: var(--sub3);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: calc(120px + 120px + 210px);
    }
    @media screen and (max-width: 767px) {
      height: auto;
      padding: 50px 0;
    }
    .wrap {
      position: absolute;
      top: 240px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 120px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
      .office-information {
        display: flex;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 1050px;
        }
        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
        }
        .title {
          width: 45%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          h2 {
            font-size: 70px;
            font-weight: 600;
            line-height: 75px;
            text-align: start;
            color: var(--sub1);
            margin-bottom: 10px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 50px;
              line-height: 50px;
            }
            @media screen and (max-width: 767px) {
              font-size: 37px;
              line-height: 37px;
              text-align: center;
            }
          }
          h3 {
            font-size: 18px;
            font-weight: 500;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }
        }
        .content {
          width: 55%;
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
          }
          .info-list {
            border-top: 1px solid #000;
            .info-row {
              display: flex;
              justify-content: space-between;
              padding: 10px 0;
              border-bottom: 1px solid #000;
              .info-title {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30%;
                font-weight: normal;
                line-height: 22px;
                padding: 5px 0;
                border-right: 1px solid #000;
              }
              .info-description {
                width: 65%;
                padding: 5px 0;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .side-cover {
      position: absolute;
      top: 120px;
      left: 0;
      width: 1600px;
      height: calc(240px + 200px);
      padding: 120px;
      z-index: 1;
      background-color: var(--white);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 60px;
        height: calc(120px + 210px);
      }
      @media screen and (max-width: 767px) {
        display: none;
      } 
    }
  }
</style>