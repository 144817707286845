<template>
  <div>
    <div
      class="title"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: false,
        speed: 0.1,
      }"
    >
      <h1>Privacy Policy</h1>
      <h2>プライバシーポリシー</h2>
    </div>
    <section>
      <div class="wrap">
        <p class="description">
          株式会社（以下、「当社」という）は、経営理念と自ら定めた行動規範に基づき、社会に貢献し、すべてのお客様からの信頼を得るとともに、当社への期待に応えるべく事業活動を進めていきます。当社がお客様から取得した自らの事業の用に供しているすべての個人情報は、皆様の大切な財産であるとともに、当社にとっても新たな価値創造の源泉となる重要資産であることを認識して、個人情報の保護を以下の基本方針に従って適切に行います。
        </p>

        <h3>第1条<span style="margin: 0.5em;"></span>個人情報の適切な取得、利用、提供に関して</h3>
        <p>
          当社では、事業の内容および規模を考慮し、業務遂行に必要な範囲で個人情報を適切な方法で取得します。個人情報の利用は、特定した利用目的の範囲を超えて行わず(ただし、一定の場合(個人情報保護法１６条３項１号〜４号)に該当する場合はその限りではありません)、またその為の措置を講ずるものとし、変更前の利用目的と関連性を有すると合理的に認められる範囲内で利用目的を変更する際には、あらかじめ当社ウェブサイトの「個人情報に関する公表事項」において変更される利用目的を公表いたします。<br>
          個人情報は、利用目的を遂行するために業務を委託する場合と利用目的を遂行するために共同利用をする場合、合併その他の事由による事業の承継に伴って個人データが提供される場合を除き、ご本人の同意なく、第三者に提供することはございません。(ただし、一定の場合(個人情報保護法２３条１項１号〜４号)に該当する限りはその限りではありません)目的外の利用や提供を防止するため、個人情報の利用および提供は、個人情報保護管理者が、その適正性を確認した上でおこなっています。<br>
          また、自主点検、内部監査等の活動を通じた統制活動も強化しております。
        </p>
        <p>当社はお問合わせ内容の確認、サービス向上の為にお客様との通話を録音させて頂く場合がございます。</p>

        <h3>第2条<span style="margin: 0.5em;"></span>個人情報保護のための安全管理策</h3>
        <ol>
          <li>
            <span class="bold title-text">個人情報の漏えい、滅失、き損等に対する予防処置</span>
            <br>当社では、個人情報の漏洩、滅失、き損等を防止するための適切なセキュリティ対策を実施しております。また、これらを徹底するため、従業員への教育・監督をおこなっています。個人情報取り扱い業務を社外に委託する場合は、委託先の選定や監督を厳重におこなっております。
          </li>
          <li>
            <span class="bold title-text">個人情報の漏えい、滅失、き損等に対する是正処置</span>
            <br>個人情報の漏えい、滅失、き損等が発生した場合は、被害拡大防止を最優先とし、速やかに是正処置を講じるとともに、原因究明をおこない、再発防止に努めます。また、事故に関する情報について、必要な範囲で関係者、関係機関に報告します。
          </li>
          <li>
            <span class="bold title-text">安全管理措置</span><br>
            <span class="medium">基本方針の策定</span><br>
            <span class="indent">個人情報の適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針を策定</span>
            <span class="medium">個人データの取扱いに関わる規律の整備</span><br>
            <span class="indent">取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人データの取扱規程を策定</span>
            <span class="medium">組織的安全管理措置</span><br>
            <span class="indent">個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業員及び当該従業員が取り扱う個人データの範囲を明確化し、法や取扱い規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備</span>
            <span class="indent">個人データの取扱状況について、定期的に自己点検を実施するとともに他部署や外部の者による監査を実施</span>
            <span class="medium">人的安全管理措置</span><br>
            <span class="indent">個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施</span>
            <span class="indent">個人データについての秘密保持に関する事項を就業規則に記載</span>
            <span class="medium">物理的安全管理措置</span><br>
            <span class="indent">個人データを取り扱う区域において、従業員の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施</span>
            <span class="indent">個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入</span>
            <span class="medium">技術的安全管理措置</span><br>
            <span class="indent">アクセス制御を実施して、担当者及び取り扱う個人情報の範囲を限定</span>
            <span class="indent">個人情報を取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入</span>
            <span class="medium">外部環境の把握</span><br>
            <span class="indent">個人データを日本国外に保管する場合には、保管する国における個人情報の保護に関する制度を把握したうえで安全管理措置を実施</span>
          </li>
        </ol>

        <h3>第3条<span style="margin: 0.5em;"></span>法令、国が定める指針、その他の規範の遵守</h3>
        <p>当社は、個人情報の取り扱いに関する法律および関連法令、主務官庁の指針、所属団体等の規範等、その他国が定める規範等を遵守します。</p>

        <h3>第4条<span style="margin: 0.5em;"></span>個人情報保護マネジメントシステムの継続的改善</h3>
        <p>当社は、個人情報の保護に関するマネジメントシステムを実行し、維持するとともに定期的に見直しをおこない、継続的改善に努めます。</p>

        <h3>第5条<span style="margin: 0.5em;"></span>共同利用</h3>
        <p>当社では、現在個人情報の共同利用は行っておりませんが、共同利用を行う場合においては、個人情報の適正な取得を行います。共同利用を行う場合は、共同利用の目的、個人情報の項目その他詳細について、別途公表することとします。</p>

        <h3>第6条<span style="margin: 0.5em;"></span>個人情報に関する苦情、相談への対応</h3>
        <p>当社に対して、個人情報の取り扱いに関する苦情、相談をおこなう場合は、下記の「個人情報お問い合わせ窓口」までご連絡ください。また、当社の個人情報保護に関するお問い合わせについても下記にて対応いたします。</p>

        <br>
        <br>
        <h4>【個人情報お問い合わせ窓口】</h4>
        <p>電話：<a href="tel:05017827335" class="phone-number">050-1782-7335</a></p>
        <p>個人情報保護管理担当者 管理部</p>
        <p>株式会社日本通信基地局</p>
        <p>住所：〒104-0061 <br class="sp-only">東京都中央区銀座1-12-4N&E BLD.7階</p>
        <p>代表取締役：谷川翔太</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyVue"
}
</script>

<style lang="scss" scoped>
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 300px;
    padding-top: 70px;
    background-image: url('@/assets/aboutus-background.webp');
    @media screen and (max-width: 767px) {
      background-image: url('@/assets/aboutus-background_sp.webp');
      height: 230px;
    }
    h1 {
      width: 100%;
      font-size: 50px;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      color: var(--sub1);
      margin-bottom: 10px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 55px;
        line-height: 55px;
      }
      @media screen and (max-width: 767px) {
        font-size: 37px;
        line-height: 37px;
      }
    }
    h2 {
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: var(--sub1);
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
  section {
    padding: 50px 0;
    .wrap {
      .description {
        margin-bottom: 40px;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        border-left: 10px solid var(--sub1);
        margin-top: 30px;
        margin-bottom: 10px;
        background-color: var(--gray);
        @media screen and (max-width: 767px) {
          padding-left: 10px;
        }
      }
      ul,ol {
        font-size: 18px;
        padding-left: 1em;
        li {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
      .title-text {
        font-size: 18px;
      }
      .bold {
        font-weight: 600;
      }
      .medium {
        font-weight: 500;
      }
      .indent {
        margin-left: 1.5em;
        display: block;
      }
    }
  }
</style>