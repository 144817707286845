<template>
  <section>
    <div class="wrap">
      <div id="achievements" class="achievements">
        <div class="title">
          <h2>Achievements</h2>
          <h3>当社の実績と紹介</h3>
        </div>
        <div class="content">
          <img class="achievements-img" src="@/assets/fv-img.webp" alt="契約数30,000超、お客様満足度 業界最高水準、販売実績3年連続 日本1位">
          <img class="achievements-img_sp" src="@/assets/fv-img_sp.webp" alt="契約数30,000超、お客様満足度 業界最高水準、販売実績3年連続 日本1位">

          <div class="location">
            <h4 class="location-title">サービス拠点</h4>
            <img src="@/assets/location.webp" alt="サービス拠点 一覧" class="location-img pc-tablet-only">
            <img src="@/assets/location_sp.webp" alt="サービス拠点 一覧" class="location-img sp-only">
          </div>

          <div class="achievements-list">
            <p class="achievement">事業の主要取引先として<br class="sp-only" />上場企業様が複数社</p>
            <p class="achievement">大手携帯キャリアが主催する<br class="sp-only tablet-only" />光回線販売コンテストで日本1位の実績</p>
            <p class="achievement">売り上げの一部を<br class="sp-only" />ジャワ等のマングローブ栽培に寄付</p>
            <p class="achievement">売り上げの一部を<br class="sp-only" />フィリピンの貧困地域の子供に寄付</p>
            <p class="caution">※上記は当社のグループ会社による実績を含みます</p>
          </div>
        </div>

        
      </div>
    </div>

    <div class="side-cover">
      <!-- 白背景 -->
    </div>
  </section>
</template>

<script>
export default {
  name: "AchievementsVue",
}
</script>

<style lang="scss" scoped>
  section {
    position: relative;
    height: calc(240px + 240px + 1900px);
    padding: 120px 0;
    background-color: var(--sub3);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: calc(120px + 120px + 1650px);
    }
    @media screen and (max-width: 767px) {
      height: auto;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .wrap {
      position: absolute;
      top: 240px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 120px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
      .achievements {
        display: block;
        height: 810px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 1050px;
        }
        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
        }
        .title {
          width: 100%;
          h2 {
            font-size: 70px;
            font-weight: 600;
            line-height: 70px;
            text-align: start;
            color: var(--sub1);
            margin-bottom: 10px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 55px;
              line-height: 55px;
            }
            @media screen and (max-width: 767px) {
              font-size: 37px;
              line-height: 37px;
              text-align: center;
            }
          }
          h3 {
            font-size: 18px;
            font-weight: 500;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }
        }
        .content {
          width: 100%;
          @media screen and (max-width: 767px) {
            padding: 0;
          }
          .achievements-img {
            width: 65%;
            margin: 60px auto;
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
          .achievements-img_sp {
            width: 100%;
            margin: 20px auto 40px auto;
            @media screen and (min-width: 768px) {
              display: none;
            }
          }
          .location {
            position: relative;
            height: 1100px;
            background-color: var(--white);
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              height: 900px;
            }
            @media screen and (max-width: 767px) {
              height: 550px;
            }
            .location-title {
              font-size: 23px;
              font-weight: 500;
              padding: 20px 0;
              text-align: center;
              color: var(--white);
              background-color: var(--sub1);
            }
            .location-img {
              position: absolute;
              top: 20px;
              left: 50%;
              transform: translateX(-50%);
              width: 880px;
              @media screen and (max-width: 767px) {
                top: 50px;
                width: 300px;
              }
            }
          }
          .achievements-list {
            .achievement {
              font-size: 23px;
              font-weight: 500;
              line-height: 25px;
              text-align: center;
              padding: 15px 0;
              border-bottom: 2px dashed var(--sub1);
              margin-top: 20px;
              color: var(--sub1);
              @media screen and (max-width: 767px) {
                font-size: 18px;
                line-height: 22px;
              }
            }
            .caution {
              font-size: 16px;
              text-align: end;
              margin-top: 30px;
              color: var(--sub1);
              @media screen and (max-width: 767px) {
                font-size: 14px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .side-cover {
      position: absolute;
      top: 120px;
      left: 0;
      width: 1600px;
      height: calc(240px + 1900px);
      padding: 120px;
      z-index: 1;
      background-color: var(--white);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 60px;
        height: calc(120px + 1650px);
      }
      @media screen and (max-width: 767px) {
        display: none;
      } 
    }
  }
</style>