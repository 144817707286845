<template>
  <section
    id="aboutus"
    v-prlx.mobile.background="{
      direction: 'y',
      fromBottom: false,
      speed: 0.1,
    }"
  >
    <div class="wrap">
    <div class="aboutus">
      <div class="title">
        <h2>About us</h2>
        <h3>株式会社日本通信基地局について</h3>
      </div>
      <div class="content">
        <p>株式会社日本通信基地局は、販売代理事業、パートナー事業 、ソリューション事業、コンテンツ卸・決済プラットフォーム事業をとおして、業界No.1の実績とNo.1の品質を達成し、顧客、取引先様、従業員、地域社会にとって必要不可欠な企業になることを目指しています。</p>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style lang="scss" scoped>
section {
  padding: 150px 0;
  background-image: url('@/assets/aboutus-background.webp');
  @media screen and (max-width: 767px) {
    background-image: url('@/assets/aboutus-background_sp.webp');
    padding: 60px 0;
  }
  .wrap {
    .aboutus {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .title {
        width: 35%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        h2 {
          font-size: 70px;
          font-weight: 600;
          line-height: 70px;
          text-align: start;
          color: var(--sub1);
          margin-bottom: 10px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            font-size: 55px;
            line-height: 55px;
          }
          @media screen and (max-width: 767px) {
            font-size: 37px;
            line-height: 37px;
            text-align: center;
          }
        }
        h3 {
          font-size: 18px;
          font-weight: 500;
          color: var(--sub1);
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            font-size: 16px;
          }
          @media screen and (max-width: 767px) {
            font-size: 16px;
            text-align: center;
            margin-bottom: 18px;
          }
        }
      }
      .content {
        width: 65%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}
</style>