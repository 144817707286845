<template>
  <div class="footer">
    <div class="other-link">
      <router-link to="/privacy" class="link" v-if="route.path !== '/privacy/'">プライバシーポリシー</router-link>
      <router-link to="/" class="link" v-if="route.path !== '/'">サイトトップ</router-link>
    </div>
    <p class="copyright">Copyright ©︎ nihon-tsushin-kichikyoku.com All Rights Reserved.</p>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
export default {
  name:  "LayoutFooter",
  data() {
    return {
      route: useRoute()
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    padding: 60px;
    background-color: var(--main);
    @media screen and (max-width: 767px) {
      padding: 40px 0
    }
    .other-link {
      display: flex;
      justify-content: space-around;
      padding: 20px 0;
      @media screen and (max-width: 767px) {
        padding: 10px 0
      }
      .link {
        color: var(--white);
        padding-bottom: 5px;
        border-bottom: 1px solid var(--white);
        cursor: pointer;
      }
    }
    .copyright {
      font-size: 14px;
      text-align: end;
      margin-top: 40px;
      margin-bottom: 0;
      color: var(--white);
      @media screen and (max-width: 767px) {
        font-size: 12px;
        margin-top: 20px;
        text-align: center;
        line-height: 16px;
      }
    }
  }
</style>