<template>
  <div id="member">
    <div v-if="spWatch">
      <Splide 
      :options="splideOptionsRight"
      :extensions="extensions"
        aria-label="お気に入りの写真"
      >
        <SplideSlide v-for="image in sp_right_images" :key="image.id">
          <img :src="require(`@/assets/member_slider/${image.imgpath}`)" :alt="image.name" />
        </SplideSlide>
      </Splide>
      <Splide 
      :options="splideOptionsLeft"
      :extensions="extensions"
        aria-label="お気に入りの写真"
      >
        <SplideSlide v-for="image in sp_left_images" :key="image.id">
          <img :src="require(`@/assets/member_slider/${image.imgpath}`)" :alt="image.name" />
        </SplideSlide>
      </Splide>
    </div>
    
    <div v-else>
      <Splide 
      :options="splideOptions"
      :extensions="extensions"
        aria-label="お気に入りの写真"
      >
        <SplideSlide v-for="image in images" :key="image.id">
          <img :src="require(`@/assets/member_slider/${image.imgpath}`)" :alt="image.name" />
        </SplideSlide>
      </Splide>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/vue-splide/css';
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';
import '@splidejs/vue-splide/css/core';

export default {
  name: "InfiniteSlider",
  components: {
    Splide,
    SplideSlide,
  },
  props: {
    images: Array,
    sp_left_images: Array,
    sp_right_images: Array,
  },
  data() {
    return {
      spWatch: true,
      splideOptions: {
        arrows: false,
        pagination: false,
        drag: false,
        swipe: false,
        rewind: true,
        type: "loop",
        perPage: 6,
        gap: '30px',
        direction: 'ltr',
        autoScroll: {
          speed: 0.5,
          pauseOnHover: false,
        },
      },
      splideOptionsRight: {
        arrows: false,
        pagination: false,
        drag: false,
        swipe: false,
        rewind: true,
        type: "loop",
        perPage: 3,
        gap: '20px',
        direction: 'ltr',
        autoScroll: {
          speed: -0.2,
          pauseOnHover: false,
        },
      },
      splideOptionsLeft: {
        arrows: false,
        pagination: false,
        drag: false,
        swipe: false,
        rewind: true,
        type: "loop",
        perPage: 3,
        gap: '20px',
        direction: 'ltr',
        autoScroll: {
          speed: 0.2,
          pauseOnHover: false,
        },
      },
      extensions: { AutoScroll },
    };
  },
  mounted() {
    this.displaySize();
  },
  methods: {
    displaySize() {
      var screenWidth = ref(window.innerWidth); // 初期値として画面幅を設定
      screenWidth.value = window.innerWidth;
      if (screenWidth.value <= 767) {
        this.spWatch = true;
      } else {
        this.spWatch = false;
      }
    },
  }
};
</script>

<style scoped>
.splide {
  padding: 10px 0;
}
</style>
